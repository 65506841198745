import Vue from 'vue'
import Vuex from 'vuex'
import Meta from 'vue-meta'
import ClientOnly from 'vue-client-only'
import NoSsr from 'vue-no-ssr'
import { createRouter } from './router.js'
import NuxtChild from './components/nuxt-child.js'
import NuxtError from '../layouts/error.vue'
import Nuxt from './components/nuxt.js'
import App from './App.js'
import { setContext, getLocation, getRouteData, normalizeError } from './utils'
import { createStore } from './store.js'

/* Plugins */

import nuxt_plugin_sentryserver_58b6a4ea from 'nuxt_plugin_sentryserver_58b6a4ea' // Source: ./sentry.server.js (mode: 'server')
import nuxt_plugin_sentryclient_6deb3c03 from 'nuxt_plugin_sentryclient_6deb3c03' // Source: ./sentry.client.js (mode: 'client')
import nuxt_plugin_distplugin69bee450_0bd738ae from 'nuxt_plugin_distplugin69bee450_0bd738ae' // Source: ./dist.plugin.69bee450.js (mode: 'client')
import nuxt_plugin_smresolver_ec07b390 from 'nuxt_plugin_smresolver_ec07b390' // Source: ./prismic/sm-resolver.js (mode: 'all')
import nuxt_plugin_nuxtvuexroutersync_ecbd00a2 from 'nuxt_plugin_nuxtvuexroutersync_ecbd00a2' // Source: ./nuxt-vuex-router-sync.js (mode: 'all')
import nuxt_plugin_prismic_2d9b59c5 from 'nuxt_plugin_prismic_2d9b59c5' // Source: ./prismic/plugins/prismic.js (mode: 'all')
import nuxt_plugin_prismiccomponents_62e248f4 from 'nuxt_plugin_prismiccomponents_62e248f4' // Source: ./prismic/plugins/prismic-components.js (mode: 'all')
import nuxt_plugin_apollomodule_05148cda from 'nuxt_plugin_apollomodule_05148cda' // Source: ./apollo-module.js (mode: 'all')
import nuxt_plugin_axios_2636b672 from 'nuxt_plugin_axios_2636b672' // Source: ./axios.js (mode: 'all')
import nuxt_plugin_router_63fc7fc8 from 'nuxt_plugin_router_63fc7fc8' // Source: ./router.js (mode: 'all')
import nuxt_plugin_shopifyplugin_60931d86 from 'nuxt_plugin_shopifyplugin_60931d86' // Source: ../plugins/shopify.plugin.js (mode: 'all')
import nuxt_plugin_filters_2dd71012 from 'nuxt_plugin_filters_2dd71012' // Source: ../plugins/filters.js (mode: 'all')
import nuxt_plugin_vuelidate_4be431c8 from 'nuxt_plugin_vuelidate_4be431c8' // Source: ../plugins/vuelidate.js (mode: 'all')
import nuxt_plugin_vuedebounce_b1e851de from 'nuxt_plugin_vuedebounce_b1e851de' // Source: ../plugins/vue-debounce.js (mode: 'all')
import nuxt_plugin_jsonld_2ff766e1 from 'nuxt_plugin_jsonld_2ff766e1' // Source: ../plugins/jsonld.js (mode: 'all')
import nuxt_plugin_locale_28023ea7 from 'nuxt_plugin_locale_28023ea7' // Source: ../plugins/locale.js (mode: 'client')
import nuxt_plugin_prismiclink_9f5cb456 from 'nuxt_plugin_prismiclink_9f5cb456' // Source: ../plugins/prismic-link.js (mode: 'client')
import nuxt_plugin_vue2smoothscroll_440a8936 from 'nuxt_plugin_vue2smoothscroll_440a8936' // Source: ../plugins/vue2-smooth-scroll.js (mode: 'client')
import nuxt_plugin_vuecountryregionselect_5048da16 from 'nuxt_plugin_vuecountryregionselect_5048da16' // Source: ../plugins/vue-country-region-select.js (mode: 'client')
import nuxt_plugin_vuemarqueetextcomponent_bc62035c from 'nuxt_plugin_vuemarqueetextcomponent_bc62035c' // Source: ../plugins/vue-marquee-text-component.js (mode: 'client')
import nuxt_plugin_vuecleavedirective_99360d78 from 'nuxt_plugin_vuecleavedirective_99360d78' // Source: ../plugins/vue-cleave-directive.js (mode: 'client')
import nuxt_plugin_clientinit_31582bdb from 'nuxt_plugin_clientinit_31582bdb' // Source: ../plugins/client-init.js (mode: 'client')
import nuxt_plugin_facebookplugin_05d332b2 from 'nuxt_plugin_facebookplugin_05d332b2' // Source: ../plugins/facebook.plugin.js (mode: 'client')
import nuxt_plugin_pinterestplugin_7701415a from 'nuxt_plugin_pinterestplugin_7701415a' // Source: ../plugins/pinterest.plugin.js (mode: 'client')
import nuxt_plugin_bingplugin_700516ac from 'nuxt_plugin_bingplugin_700516ac' // Source: ../plugins/bing.plugin.js (mode: 'client')
import nuxt_plugin_northbeamplugin_0cb66374 from 'nuxt_plugin_northbeamplugin_0cb66374' // Source: ../plugins/northbeam.plugin.js (mode: 'client')
import nuxt_plugin_klaviyoplugin_f8024cae from 'nuxt_plugin_klaviyoplugin_f8024cae' // Source: ../plugins/klaviyo.plugin.js (mode: 'client')
import nuxt_plugin_smileplugin_1aedc048 from 'nuxt_plugin_smileplugin_1aedc048' // Source: ../plugins/smile.plugin.js (mode: 'client')
import nuxt_plugin_typeformplugin_db0fbe2c from 'nuxt_plugin_typeformplugin_db0fbe2c' // Source: ../plugins/typeform.plugin.js (mode: 'client')
import nuxt_plugin_liveChatplugin_5578e660 from 'nuxt_plugin_liveChatplugin_5578e660' // Source: ../plugins/liveChat.plugin.js (mode: 'client')
import nuxt_plugin_googleanalytics_2bcb2ee2 from 'nuxt_plugin_googleanalytics_2bcb2ee2' // Source: ../plugins/google-analytics.js (mode: 'client')
import nuxt_plugin_myshelfplugin_36eb833e from 'nuxt_plugin_myshelfplugin_36eb833e' // Source: ../plugins/myshelf.plugin.js (mode: 'client')

// Component: <ClientOnly>
Vue.component(ClientOnly.name, ClientOnly)

// TODO: Remove in Nuxt 3: <NoSsr>
Vue.component(NoSsr.name, {
  ...NoSsr,
  render (h, ctx) {
    if (process.client && !NoSsr._warned) {
      NoSsr._warned = true

      console.warn('<no-ssr> has been deprecated and will be removed in Nuxt 3, please use <client-only> instead')
    }
    return NoSsr.render(h, ctx)
  }
})

// Component: <NuxtChild>
Vue.component(NuxtChild.name, NuxtChild)
Vue.component('NChild', NuxtChild)

// Component NuxtLink is imported in server.js or client.js

// Component: <Nuxt>
Vue.component(Nuxt.name, Nuxt)

Object.defineProperty(Vue.prototype, '$nuxt', {
  get() {
    const globalNuxt = this.$root.$options.$nuxt
    if (process.client && !globalNuxt && typeof window !== 'undefined') {
      return window.$nuxt
    }
    return globalNuxt
  },
  configurable: true
})

Vue.use(Meta, {"keyName":"head","attribute":"data-n-head","ssrAttribute":"data-n-head-ssr","tagIDKeyName":"hid"})

const defaultTransition = {"name":"page","mode":"out-in","appear":false,"appearClass":"appear","appearActiveClass":"appear-active","appearToClass":"appear-to"}

const originalRegisterModule = Vuex.Store.prototype.registerModule

function registerModule (path, rawModule, options = {}) {
  const preserveState = process.client && (
    Array.isArray(path)
      ? !!path.reduce((namespacedState, path) => namespacedState && namespacedState[path], this.state)
      : path in this.state
  )
  return originalRegisterModule.call(this, path, rawModule, { preserveState, ...options })
}

async function createApp(ssrContext, config = {}) {
  const router = await createRouter(ssrContext, config)

  const store = createStore(ssrContext)
  // Add this.$router into store actions/mutations
  store.$router = router

  // Fix SSR caveat https://github.com/nuxt/nuxt.js/issues/3757#issuecomment-414689141
  store.registerModule = registerModule

  // Create Root instance

  // here we inject the router and store to all child components,
  // making them available everywhere as `this.$router` and `this.$store`.
  const app = {
    head: {"htmlAttrs":{"lang":"en-ca"},"title":"Gee Beauty","meta":[{"charset":"utf-8"},{"name":"viewport","content":"width=device-width, initial-scale=1, maximum-scale=1"},{"name":"google-site-verification","content":"usYnOjw8ky3n2hz3ggaowPjObW4V3SIOAZ_-4pN80-U"},{"name":"google-side-verification","content":"nT4QuINsuMBLOTuL-M5BSpIYmPSEZOzcO4n7yDmNcFo"},{"name":"msvalidate.01","content":"9f48944ab0dd37989d4261673ee1d480"},{"name":"p:domain_verify","content":"9f48944ab0dd37989d4261673ee1d480"}],"link":[{"rel":"icon","type":"image\u002Fpng","href":"\u002Ffavicon.png"}],"script":[{"src":"https:\u002F\u002Fpolyfill.io\u002Fv3\u002Fpolyfill.min.js?features=IntersectionObserver","body":true},{"src":"https:\u002F\u002Fstatic.shopmy.us\u002FAffiliates\u002Fsms_aff_clicktrack_geebeauty.js","body":true,"defer":true}],"style":[]},

    store,
    router,
    nuxt: {
      defaultTransition,
      transitions: [defaultTransition],
      setTransitions (transitions) {
        if (!Array.isArray(transitions)) {
          transitions = [transitions]
        }
        transitions = transitions.map((transition) => {
          if (!transition) {
            transition = defaultTransition
          } else if (typeof transition === 'string') {
            transition = Object.assign({}, defaultTransition, { name: transition })
          } else {
            transition = Object.assign({}, defaultTransition, transition)
          }
          return transition
        })
        this.$options.nuxt.transitions = transitions
        return transitions
      },

      err: null,
      dateErr: null,
      error (err) {
        err = err || null
        app.context._errored = Boolean(err)
        err = err ? normalizeError(err) : null
        let nuxt = app.nuxt // to work with @vue/composition-api, see https://github.com/nuxt/nuxt.js/issues/6517#issuecomment-573280207
        if (this) {
          nuxt = this.nuxt || this.$options.nuxt
        }
        nuxt.dateErr = Date.now()
        nuxt.err = err
        // Used in src/server.js
        if (ssrContext) {
          ssrContext.nuxt.error = err
        }
        return err
      }
    },
    ...App
  }

  // Make app available into store via this.app
  store.app = app

  const next = ssrContext ? ssrContext.next : location => app.router.push(location)
  // Resolve route
  let route
  if (ssrContext) {
    route = router.resolve(ssrContext.url).route
  } else {
    const path = getLocation(router.options.base, router.options.mode)
    route = router.resolve(path).route
  }

  // Set context to app.context
  await setContext(app, {
    store,
    route,
    next,
    error: app.nuxt.error.bind(app),
    payload: ssrContext ? ssrContext.payload : undefined,
    req: ssrContext ? ssrContext.req : undefined,
    res: ssrContext ? ssrContext.res : undefined,
    beforeRenderFns: ssrContext ? ssrContext.beforeRenderFns : undefined,
    ssrContext
  })

  function inject(key, value) {
    if (!key) {
      throw new Error('inject(key, value) has no key provided')
    }
    if (value === undefined) {
      throw new Error(`inject('${key}', value) has no value provided`)
    }

    key = '$' + key
    // Add into app
    app[key] = value
    // Add into context
    if (!app.context[key]) {
      app.context[key] = value
    }

    // Add into store
    store[key] = app[key]

    // Check if plugin not already installed
    const installKey = '__nuxt_' + key + '_installed__'
    if (Vue[installKey]) {
      return
    }
    Vue[installKey] = true
    // Call Vue.use() to install the plugin into vm
    Vue.use(() => {
      if (!Object.prototype.hasOwnProperty.call(Vue.prototype, key)) {
        Object.defineProperty(Vue.prototype, key, {
          get () {
            return this.$root.$options[key]
          }
        })
      }
    })
  }

  // Inject runtime config as $config
  inject('config', config)

  if (process.client) {
    // Replace store state before plugins execution
    if (window.__NUXT__ && window.__NUXT__.state) {
      store.replaceState(window.__NUXT__.state)
    }
  }

  // Add enablePreview(previewData = {}) in context for plugins
  if (process.static && process.client) {
    app.context.enablePreview = function (previewData = {}) {
      app.previewData = Object.assign({}, previewData)
      inject('preview', previewData)
    }
  }
  // Plugin execution

  if (process.server && typeof nuxt_plugin_sentryserver_58b6a4ea === 'function') {
    await nuxt_plugin_sentryserver_58b6a4ea(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_sentryclient_6deb3c03 === 'function') {
    await nuxt_plugin_sentryclient_6deb3c03(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_distplugin69bee450_0bd738ae === 'function') {
    await nuxt_plugin_distplugin69bee450_0bd738ae(app.context, inject)
  }

  if (typeof nuxt_plugin_smresolver_ec07b390 === 'function') {
    await nuxt_plugin_smresolver_ec07b390(app.context, inject)
  }

  if (typeof nuxt_plugin_nuxtvuexroutersync_ecbd00a2 === 'function') {
    await nuxt_plugin_nuxtvuexroutersync_ecbd00a2(app.context, inject)
  }

  if (typeof nuxt_plugin_prismic_2d9b59c5 === 'function') {
    await nuxt_plugin_prismic_2d9b59c5(app.context, inject)
  }

  if (typeof nuxt_plugin_prismiccomponents_62e248f4 === 'function') {
    await nuxt_plugin_prismiccomponents_62e248f4(app.context, inject)
  }

  if (typeof nuxt_plugin_apollomodule_05148cda === 'function') {
    await nuxt_plugin_apollomodule_05148cda(app.context, inject)
  }

  if (typeof nuxt_plugin_axios_2636b672 === 'function') {
    await nuxt_plugin_axios_2636b672(app.context, inject)
  }

  if (typeof nuxt_plugin_router_63fc7fc8 === 'function') {
    await nuxt_plugin_router_63fc7fc8(app.context, inject)
  }

  if (typeof nuxt_plugin_shopifyplugin_60931d86 === 'function') {
    await nuxt_plugin_shopifyplugin_60931d86(app.context, inject)
  }

  if (typeof nuxt_plugin_filters_2dd71012 === 'function') {
    await nuxt_plugin_filters_2dd71012(app.context, inject)
  }

  if (typeof nuxt_plugin_vuelidate_4be431c8 === 'function') {
    await nuxt_plugin_vuelidate_4be431c8(app.context, inject)
  }

  if (typeof nuxt_plugin_vuedebounce_b1e851de === 'function') {
    await nuxt_plugin_vuedebounce_b1e851de(app.context, inject)
  }

  if (typeof nuxt_plugin_jsonld_2ff766e1 === 'function') {
    await nuxt_plugin_jsonld_2ff766e1(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_locale_28023ea7 === 'function') {
    await nuxt_plugin_locale_28023ea7(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_prismiclink_9f5cb456 === 'function') {
    await nuxt_plugin_prismiclink_9f5cb456(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_vue2smoothscroll_440a8936 === 'function') {
    await nuxt_plugin_vue2smoothscroll_440a8936(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_vuecountryregionselect_5048da16 === 'function') {
    await nuxt_plugin_vuecountryregionselect_5048da16(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_vuemarqueetextcomponent_bc62035c === 'function') {
    await nuxt_plugin_vuemarqueetextcomponent_bc62035c(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_vuecleavedirective_99360d78 === 'function') {
    await nuxt_plugin_vuecleavedirective_99360d78(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_clientinit_31582bdb === 'function') {
    await nuxt_plugin_clientinit_31582bdb(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_facebookplugin_05d332b2 === 'function') {
    await nuxt_plugin_facebookplugin_05d332b2(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_pinterestplugin_7701415a === 'function') {
    await nuxt_plugin_pinterestplugin_7701415a(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_bingplugin_700516ac === 'function') {
    await nuxt_plugin_bingplugin_700516ac(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_northbeamplugin_0cb66374 === 'function') {
    await nuxt_plugin_northbeamplugin_0cb66374(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_klaviyoplugin_f8024cae === 'function') {
    await nuxt_plugin_klaviyoplugin_f8024cae(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_smileplugin_1aedc048 === 'function') {
    await nuxt_plugin_smileplugin_1aedc048(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_typeformplugin_db0fbe2c === 'function') {
    await nuxt_plugin_typeformplugin_db0fbe2c(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_liveChatplugin_5578e660 === 'function') {
    await nuxt_plugin_liveChatplugin_5578e660(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_googleanalytics_2bcb2ee2 === 'function') {
    await nuxt_plugin_googleanalytics_2bcb2ee2(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_myshelfplugin_36eb833e === 'function') {
    await nuxt_plugin_myshelfplugin_36eb833e(app.context, inject)
  }

  // Lock enablePreview in context
  if (process.static && process.client) {
    app.context.enablePreview = function () {
      console.warn('You cannot call enablePreview() outside a plugin.')
    }
  }

  // Wait for async component to be resolved first
  await new Promise((resolve, reject) => {
    // Ignore 404s rather than blindly replacing URL in browser
    if (process.client) {
      const { route } = router.resolve(app.context.route.fullPath)
      if (!route.matched.length) {
        return resolve()
      }
    }
    router.replace(app.context.route.fullPath, resolve, (err) => {
      // https://github.com/vuejs/vue-router/blob/v3.4.3/src/util/errors.js
      if (!err._isRouter) return reject(err)
      if (err.type !== 2 /* NavigationFailureType.redirected */) return resolve()

      // navigated to a different route in router guard
      const unregister = router.afterEach(async (to, from) => {
        if (process.server && ssrContext && ssrContext.url) {
          ssrContext.url = to.fullPath
        }
        app.context.route = await getRouteData(to)
        app.context.params = to.params || {}
        app.context.query = to.query || {}
        unregister()
        resolve()
      })
    })
  })

  return {
    store,
    app,
    router
  }
}

export { createApp, NuxtError }
